import utils from "../utils/utils";
import anime from "../libs/anime";
import Hammer from "../libs/hammer";
import globalVar from "../utils/globalVar";

class Voyages {
	constructor() {
		let cache = {};
		let priceContainerHeight, priceContainerTop;
		let ticking = false;
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			if (document.querySelector(".notif")) {
				cache.notif = document.querySelector(".notif .text_notif");
				cache.notifClick = document.querySelector(".notif .click_notif");
			}
			if (document.querySelector(".galerie_img")) {
				cache.galerie = document.querySelector(".galerie_img");
				cache.galerieMin = document.querySelectorAll(
					".galerie_min .img_container"
				);
				cache.arrowLeft = document.querySelector(".galerie_img .arrow_left");
				cache.arrowRight = document.querySelector(".galerie_img .arrow_right");
				cache.openGalerie = document.querySelectorAll(".click_galerie");
				cache.closeGalerie = cache.galerie.querySelectorAll(".close");
			}
			if (document.querySelector(".price_container")) {
				cache.priceContainer = document.querySelector(".price_container");
				cache.stickyBar = document.querySelector(".sticky-price-bar");
			}
		};

		let registerEvents = () => {
			if (document.querySelector(".notif")) {
				cache.notifClick.addEventListener("click", showNotif);
			}
			if (document.querySelector(".galerie_img")) {
				cache.arrowLeft.addEventListener("click", slideLeft);
				cache.arrowRight.addEventListener("click", slideRight);
				for (let i = 0; i < cache.openGalerie.length; i++) {
					cache.openGalerie[i].addEventListener("click", openGalerie);
				}
				for (let i = 0; i < cache.closeGalerie.length; i++) {
					cache.closeGalerie[i].addEventListener("click", closeGalerie);
				}
				for (let i = 0; i < cache.galerieMin.length; i++) {
					cache.galerieMin[i].addEventListener("click", function() {
						openGalerie();
						setActiveSlide(i);
					});
				}

				let hammertime = new Hammer(
					document.querySelector(".galerie_img .galerie_container")
				);
				hammertime.on("swipe", function(ev) {
					if (ev.direction == 2) {
						cache.arrowRight.click();
					}
					if (ev.direction == 4) {
						cache.arrowLeft.click();
					}
				});
			}

			if (cache.priceContainer && cache.stickyBar) {
				priceContainerHeight = cache.priceContainer.offsetHeight;
				priceContainerTop = cache.priceContainer.offsetTop;
				checkStickyBarVisibility();

				window.addEventListener(
					"scroll",
					function() {
						if (!ticking) {
							window.requestAnimationFrame(function() {
								checkStickyBarVisibility();
								ticking = false;
							});
							ticking = true;
						}
					},
					{ passive: true }
				);

				window.addEventListener(
					"resize",
					function() {
						priceContainerHeight = cache.priceContainer.offsetHeight;
						priceContainerTop = cache.priceContainer.offsetTop;
						checkStickyBarVisibility();
					},
					{ passive: true }
				);
			}
		};

		let checkStickyBarVisibility = () => {
			if (!cache.stickyBar) return;

			const scrollThreshold = 900; // 900px

			const currentScroll = window.scrollY || window.pageYOffset;

			if (currentScroll > scrollThreshold) {
				if (cache.stickyBar.style.display !== "flex") {
					cache.stickyBar.style.display = "flex";
					setTimeout(() => {
						cache.stickyBar.classList.add("visible");
					}, 10);
				}
			} else {
				cache.stickyBar.classList.remove("visible");
				setTimeout(() => {
					if (!cache.stickyBar.classList.contains("visible")) {
						cache.stickyBar.style.display = "none";
					}
				}, 300);
			}
		};

		let showNotif = function() {
			cache.notifClick.removeEventListener("click", showNotif);
			cache.notifClick.classList.add("open");
			cache.notif.style.display = "block";
			anime({
				targets: cache.notif,
				duration: 300,
				scale: [0.8, 1],
				opacity: [0, 1],
				easing: "easeOutCubic",
				complete: function() {
					cache.notifClick.addEventListener("click", hideNotif);
				},
			});
		};
		let hideNotif = function() {
			cache.notifClick.removeEventListener("click", hideNotif);
			cache.notifClick.classList.remove("open");
			anime({
				targets: cache.notif,
				duration: 300,
				scale: [1, 0.8],
				opacity: [1, 0],
				easing: "easeOutCubic",
				complete: function() {
					cache.notif.style.display = "none";
					cache.notifClick.addEventListener("click", showNotif);
				},
			});
		};

		let openGalerie = function() {
			utils.disableScroll();
			cache.galerie.style.display = "block";
			anime({
				targets: cache.galerie,
				duration: 600,
				scale: [0.6, 1],
				opacity: [0, 1],
				easing: "easeInOutCubic",
			});
		};

		let closeGalerie = function() {
			utils.enableScroll();
			anime({
				targets: cache.galerie,
				duration: 600,
				scale: [1, 0.6],
				opacity: [1, 0],
				easing: "easeInOutCubic",
				complete: function() {
					cache.galerie.style.display = "none";
				},
			});
		};

		let slideLeft = function() {
			const slider = this.closest(".galerie_container");
			const slidesImg = slider.querySelectorAll(".img_container");
			let index = parseInt(slider.dataset.index);
			cache.arrowLeft.style.pointerEvents = "none";
			const currentSlideImg = slidesImg[index];

			if (index > 0) {
				index--;
			} else {
				index = slidesImg.length - 1;
			}
			slider.dataset.index = index;
			slider.querySelector(".slide_nb .nb").innerHTML = index + 1;
			const targetSlideImg = slidesImg[index];

			anime({
				targets: currentSlideImg,
				duration: 800,
				translateX: ["0%", "15%"],
				opacity: [1, 0],
				easing: "easeInOutCubic",
				complete: function() {
					cache.arrowLeft.style.pointerEvents = "all";
				},
			});
			anime({
				targets: targetSlideImg,
				duration: 800,
				translateX: ["-15%", "0%"],
				opacity: [0, 1],
				delay: 100,
				easing: "easeInOutCubic",
			});
		};

		let slideRight = function() {
			const slider = this.closest(".galerie_container");
			const slidesImg = slider.querySelectorAll(".img_container");
			let index = parseInt(slider.dataset.index);
			cache.arrowRight.style.pointerEvents = "none";
			const currentSlideImg = slidesImg[index];

			if (index < slidesImg.length - 1) {
				index++;
			} else {
				index = 0;
			}
			slider.dataset.index = index;
			slider.querySelector(".slide_nb .nb").innerHTML = index + 1;
			const targetSlideImg = slidesImg[index];

			anime({
				targets: currentSlideImg,
				duration: 800,
				translateX: ["0%", "-15%"],
				opacity: [1, 0],
				easing: "easeInOutCubic",
				complete: function() {
					cache.arrowRight.style.pointerEvents = "all";
				},
			});
			anime({
				targets: targetSlideImg,
				duration: 800,
				translateX: ["15%", "0%"],
				opacity: [0, 1],
				easing: "easeInOutCubic",
			});
		};

		let setActiveSlide = function(index) {
			const slider = cache.galerie.querySelector(".galerie_container");
			const slidesImg = slider.querySelectorAll(".img_container");

			slider.dataset.index = index;
			slider.querySelector(".slide_nb .nb").innerHTML = index + 1;

			slidesImg.forEach((img, i) => {
				img.style.opacity = i === index ? "1" : "0";
				img.style.transform =
					i === index ? "translateX(0%)" : "translateX(15%)";
			});
		};

		initFunc();
	}
}
export { Voyages };
